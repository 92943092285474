import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { autobind } from "Utilities";
import BlogList from "../BlogList/BlogList";
import Markdown from "../Markdown/Markdown";
import blogDetails from "../../blog-posts/blogs.json";
import styles from "./Blog.scss";

const propTypes = {
    isMobile: PropTypes.bool
};

const defaultProps = {
    isMobile: false
};

class Blog extends Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.blogPosts = {};
        this.importFiles(require.context("../../blog-posts/", true, /(\.md|\.png)$/), this.blogPosts);
    }

    importFiles(r, d) {
        r.keys().forEach((key) => {
            const filename = key.substring(2, key.lastIndexOf("."));

            d[filename] = r(key);
        });
    }

    handleLoadPost(filename) {
        const { history } = this.props;

        history.push(`/blog/${filename}`);
    }

    renderBlogPost(props) {
        const { match } = props;
        const post = match.params.blog;
        let blogPost = null;

        if (post) {
            blogPost = (<Markdown className={styles.post} content={this.blogPosts[post]} />)
        }

        return blogPost;
    }

    renderBlogNavigation() {
        const { isMobile } = this.props;
        let navigation = null;

        if (isMobile) {

        }
        else {
            navigation = (
                <BlogList
                    className={styles.list}
                    blogs={blogDetails}
                    onClick={this.handleLoadPost}
                />
            );
        }

        return navigation;
    }

    render() {
        return (
            <section className={styles.blogContainer}>
                <Route path="/blog/:blog" render={this.renderBlogPost} />
                {this.renderBlogNavigation()}
            </section>
        );
    }
}

Blog.propTypes = propTypes;
Blog.defaultProps = defaultProps;

export default Blog;