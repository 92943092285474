import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardContent, Typography } from "Controls";
import styles from "./BlogList.scss";

const propTypes = {
    blogs: PropTypes.object.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func
};

class BlogList extends Component {
    handleLoad(post) {
        const { onClick } = this.props;
        
        if (onClick) {
            onClick(post.filename);
        }
    }

    render() {
        const { blogs, className } = this.props;

        const cards = blogs.posts.map((post) => {
            return (
                <Card className={styles.card} key={post.filename} onClick={() => { this.handleLoad(post) }}>
                    <CardHeader
                        title={post.title}
                        subheader={post.date}
                        classes={{ root: styles.headerRoot, subheader: styles.subheader, title: styles.title }}
                    />
                    <CardContent className={styles.content}>
                        <Typography classes={{ body2: styles.contentText }}>
                            {post.summary}
                        </Typography>
                    </CardContent>
                </Card>
            );
        });

        return (<aside className={className}>{cards}</aside>);
    }
}

BlogList.propTypes = propTypes;

export default BlogList;