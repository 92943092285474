import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import styles from "./Markdown.scss"

const wrapMarkup = html => ({
  __html: html,
});

const propTypes = {
    content: PropTypes.string.isRequired,
    className: PropTypes.string
};

class Markdown extends Component {
    render () {
        const { content, className } = this.props;
        const classes = classNames(styles.markdown, className);

        // eslint-disable-next-line react/no-danger
        return (<div className={classes} dangerouslySetInnerHTML={wrapMarkup(content)} />);
    }
}

Markdown.propTypes = propTypes;

export default Markdown;