import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import classNames from "classnames";
import { autobind, displayOptions, getDisplayDetails } from "Utilities";
import Navigation from "./Navigation";
import Menu from "./Menu";
import Home from "./Home/Home";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Work from "./Work/Work";
import Resume from "./Resume/Resume";
import Projects from "./Projects/Projects";
import Streams from "./Streams/Streams";
import Blog from "./Blog/Blog";
import styles from "./Layout.scss";

class Layout extends Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            isMenuExpanded: false,
            displaySettings: {
                orientation: displayOptions.orientations.PORTRAIT,
                formFactor: displayOptions.formFactors.MOBILE
            }
        };
    }
    
    componentDidMount() {
        window.addEventListener("resize", this.onWindowResize, false);

        setTimeout(() => {
            requestAnimationFrame(this.onWindowResize);
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowResize);
    }

    onWindowResize() {
        const displaySettings = getDisplayDetails(window.innerHeight, window.innerWidth);

        this.setState({
            displaySettings
        });
    }

    onCloseMenu() {
        this.onToggleMenu(false);
    }

    onOpenMenu() {
        this.onToggleMenu(true);
    }

    onToggleMenu(isExpanded) {
        this.setState({
            isMenuExpanded: isExpanded
        });
    }

    render() {
        const { isMenuExpanded, displaySettings } = this.state;
        const { orientations, formFactors } = displayOptions;
        const hideToolBar = (displaySettings.formFactor === formFactors.MOBILE || (displaySettings.formFactor === formFactors.TABLET && displaySettings.orientation === orientations.LANDSCAPE));
        const mainClasses = classNames(styles.content, {
            [styles.mobile]: !hideToolBar
        });

        return (
            <Fragment>
                <Navigation
                    isMenuOpen={isMenuExpanded}
                    isMenuToolbar={!hideToolBar}
                    onToggleMenu={this.onToggleMenu}
                />
                <Menu
                    isMenuToolbar={!hideToolBar}
                    isExpanded={isMenuExpanded}
                    onClose={this.onCloseMenu}
                    onOpen={this.onOpenMenu}
                />
                <main className={mainClasses}>
                    <Switch>
                        <Route exact={true} path="/" component={Home} />
                        <Route path="/home" component={Home} />
                        <Route path="/about" component={About} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/work" component={Work} />
                        <Route path="/Resume" component={Resume} />
                        <Route path="/projects" component={Projects} />
                        <Route path="/streams" component={Streams} />
                        <Route path="/blog" component={Blog} />
                    </Switch>
                </main>
            </Fragment>
        );
    }
}

export default Layout;