import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Divider, List, ListSubHeader, SwipeableDrawer, withStyles } from "Controls";
import { BusinessIcon, CodeIcon, ContactMailIcon, HeadsetMicIcon, HomeIcon, InsertDriveFileIcon, PersonIcon, RssFeedIcon } from "Icons";
import MenuItemLink from "./MenuItemLink";
import NinjaLogo from "./NinjaLogo";
import styles from "./Menu.scss";

const themeStyles = (theme) => ({
    toolbarPadder: theme.mixins.toolbar
});

const propTypes = {
    classes: PropTypes.object.isRequired,
    isMenuToolbar: PropTypes.bool,
    isExpanded: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onOpen: PropTypes.func
};

const defaultProps = {
    isExpanded: false
};

class Menu extends Component {
    renderListItem(text, route, Icon) {
        const { onClose } = this.props;
        const icon = (Icon) ? <Icon /> : null;

        return (
            <MenuItemLink
                icon={icon}
                primary={text}
                route={route}
                onClick={onClose}
                classes={{
                    listItem: styles.menuItem,
                    icon: styles.icon,
                    text: styles.text
                }}
            />
        );
    }

    render() {
        const { classes, isMenuToolbar, isExpanded, onClose, onOpen } = this.props;
        const drawerVariant = (isMenuToolbar) ? "permanent" : "temporary";
        const drawerClasses = classNames(styles.drawer, {
            [styles.collapsed]: !isExpanded
        });
        const professionalHeader = (!isExpanded) ? null : (
            <ListSubHeader className={styles.subHeader}>{"Professional"}</ListSubHeader>
        );
        const personalHeader = (!isExpanded) ? null : (
            <ListSubHeader className={styles.subHeader}>{"Personal"}</ListSubHeader>
        );
        const toolbarPadder = (isMenuToolbar) ? (<div className={classes.toolbarPadder}></div>) : null;

        return (
            <SwipeableDrawer
                className={drawerClasses}
                classes={{ paper: drawerClasses }}
                onOpen={onOpen}
                onClose={onClose}
                open={isExpanded}
                variant={drawerVariant}
            >
                {toolbarPadder}
                <List>
                    {this.renderListItem("Home", "/", HomeIcon)}
                    {this.renderListItem("About Me", "/about", PersonIcon)}
                    {this.renderListItem("Contact", "/contact", ContactMailIcon)}

                    <Divider className={styles.divider} />
                    {professionalHeader}
                    {this.renderListItem("Work", "/work", BusinessIcon)}
                    {this.renderListItem("Resume", "/resume", InsertDriveFileIcon)}
                    {this.renderListItem("Projects", "/projects", CodeIcon)}
                    
                    <Divider className={styles.divider}  />
                    {personalHeader}
                    {this.renderListItem("Streams", "/streams", HeadsetMicIcon)}
                    {this.renderListItem("Blog", "/blog", RssFeedIcon)}
                </List>
                <NinjaLogo />
            </SwipeableDrawer>
        );
    }
}

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default withStyles(themeStyles)(Menu);