import React, { Component, Fragment } from "react";
import { autobind } from "Utilities";
import Streamers from "../Streamers/Streamers";
import styles from "./Streams.scss";

class Streams extends Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    render() {
        return (
            <Fragment>
                <section className={styles.container}>
                    {/* <iframe className={styles.stream} allowfullscreen="true" src="https://mixer.com/embed/player/ninj4neer?disableLowLatency=1"></iframe> */}
                    <Streamers />
                </section>
            </Fragment>
        );
    }
}

export default Streams;