import React, { Component } from "react";
import { Typography } from "Controls";
import SocialMedia from "../SocialMedia/SocialMedia";
import globalStyles from "../../styles/styles.scss";

class Contact extends Component {
    render() {
        return (
            <section>
                <header>
                    <Typography variant="h5">{"Contact Me"}</Typography>
                </header>
                <article className={globalStyles.article}>

                </article>

                <SocialMedia />
            </section>
        );
    }
}

export default Contact;