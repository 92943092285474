import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "Controls";
import { autobind } from "Utilities";

const propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string,
    route: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    classes: PropTypes.object
};

class MenuItemLink extends Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    renderLink(itemProps) {
        const { route } = this.props;
        
        return (<Link to={route} {...itemProps} />);
    }

    render() {
        const { icon, primary, secondary, classes, onClick } = this.props;
        const { listItem: listItemClasses, icon: iconClasses, text: textClasses } = classes || {};
        const iconListItem = (icon === undefined || icon === null) ? null : (
            <ListItemIcon className={iconClasses}>{icon}</ListItemIcon>
        );

        return (
            <ListItem
                button={true}
                className={listItemClasses}
                disableGutters={true}
                key={primary}
                onClick={onClick}
                component={this.renderLink}
            >
                {iconListItem}
                <ListItemText
                    className={textClasses}
                    primary={primary}
                    secondary={secondary}
                    classes={{
                        primary: textClasses,
                        secondary: textClasses
                    }}
                />
            </ListItem>
        );
    }
}

MenuItemLink.propTypes = propTypes;

export default MenuItemLink;