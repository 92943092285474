import React from "react";
import { codeBanner } from "Images";
import styles from "./Banner.scss";

const Banner = () => {
    return (
        <div
            className={styles.banner}
            style={{ backgroundImage: `url('${codeBanner}')` }}
        />
    );
};

export default Banner;