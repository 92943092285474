import React, { Component } from "react";
import { Typography } from "Controls";
import globalStyles from "../../styles/styles.scss";

class About extends Component {
    render() {
        return (
            <section>
                <Typography variant="h5">{"About Me"}</Typography>

                <article className={globalStyles.article}>
                    <Typography className={globalStyles.paragraph}>
                        {"My name is Sara O'Neal. I'm a software engineer, Christian, gamer, mentor, geek, techie, and baseball fanatic. "}
                        {"I have an addictive personality, so if I enjoy something like programming or gaming, I can do it for hours on end without even realizing it. "}
                    </Typography>
                </article>

                <header className={globalStyles.header}>
                    <Typography variant="h6">{"Education"}</Typography>
                </header>
                <article className={globalStyles.article}>
                    <Typography className={globalStyles.paragraph}>
                        {"I attended Cleveland State Community College from 2000 - 2002. "}
                        {"I received the Mathematics Most Oustanding Student Award during my sophomore year in 2002. "}
                    </Typography>
                    <Typography className={globalStyles.paragraph}>
                        {"Next, I attended Maryville College from 2002-2004 and received my B.A. in Computer Science, graduating Summa Cum Laude. "}
                        {"Yes, you read that correctly, a Bachelor of ARTS degree in Computer SCIENCE. "}
                        {"I also received the 2004 Jerry L. Pietenpol Computer Science Award, which recognizes the most outstanding and promising junior or senior majoring in computer science. "}
                        {"While at MC, I joined a competitive programming team, appropriately dubbed the MC++ Computer Geeks. "}
                        {"The MC++ Computer Geeks participated in multiple progamming competitions sponsored by the ACM, CCSC (Consortium for Computing Sciences in Colleges), and MCSC. "}
                        {"We placed 5th out of 30 teams in the CCSC competition, correctly solving all problems, just not the fastest. "}
                    </Typography>
                    <Typography className={globalStyles.paragraph}>
                        {"After graduating from Maryville College, I decided to pursue my master's degree in computer science at The University of Tennessee, Knoxville. "}
                        {"I attended UTK from 2004 - 2007, graduating with my M.S. in Computer Science in December 2007. "}
                        {"While at UTK I was a Graduate Teaching Assistant for the Introductory to Computer Science Course for 5 semesters. "}
                        {"Some of the courses I studied in the master's program include - System Programming, Operating Systems, Data Mining, GUI Design, Databases, and Advanced Design and Analysis of Algorithms. "}
                        {"I also worked on a Project in Lieu of Thesis with Dr. Brad Vander Zanden to build a visual interface that demonstrated common algorithms and operations performed on linked lists. "}
                    </Typography>
                </article>

                <header className={globalStyles.header}>
                    <Typography variant="h6">{"Software Development"}</Typography>
                </header>
                <article className={globalStyles.article}>
                    <Typography className={globalStyles.paragraph}>
                        {"I currently work for a Software Consulting company in Knoxville, TN called Cadre5. "}
                        {"I specialize in full-stack web development, architecture, and devops. "}
                        {"I have extensive experience in SQL, C#, ASP.NET MVC, JavaScript, ES6, React, Redux, React Router, Webpack, MSBuild, TeamCity, Octopus Deploy, Docker, IIS, and Azure. "}
                        {"Additionaly, I have some experience in Node.js, C, C++, Java, PHP, Perl, Python, SSRS, and Powershell. "}
                    </Typography>
                </article>

                <header className={globalStyles.header}>
                    <Typography variant="h6">{"Hobbies and Interests"}</Typography>
                </header>
                <article className={globalStyles.article}>
                    <Typography variant="subtitle1">{"Baseball"}</Typography>
                    <Typography className={globalStyles.paragraph}>
                        {"I have been a fan of baseball, and more specifically the Atlanta Braves since their World Series season in 1991. "}
                        {"Growing up I attended numerous spring training games, as well as games at Turner Field in Atlanta, GA. "}
                        {"While travelling, I've also visited T-Mobile Park in Seattle, WA and Citizens Bank Park in Philadelphia, PA. "}
                        {"I've been collecting baseball cards since I was a kid, accumlating more than 30,000 cards. "}
                        {"Initially, I tried to collect all Braves cards, but decided that was too ambitious. "}
                        {"After a few years I really started to follow the career of MVP and Hall of Famer, Chipper Jones. "}
                        {"At that point I began specifically trying to collect his baseball cards, game-used, and autographed memorabilia. "}
                        {"I have two baseball bats (left and right-handed), baseball, helmet, cards, and photos all autographed by Chipper. "}
                        {"After Chipper's retirement, I started trying to collect Bowman rookie cards of various players. "}
                    </Typography>

                    <Typography variant="subtitle1">{"Gaming"}</Typography>
                    <Typography className={globalStyles.paragraph}>
                        {"I've been an avid gamer since early childhood. My very first gaming console was the Nintendo Entertainment System. "}
                        {"I've owned various gaming consoles that include SNES, Sega Genesis, Nintendo 64, Sega Game Gear, Playstation 1 & 4, PSP, and Xbox One. "}
                        {"Some of my favorite console games include Mario, Sonic the Hedgehog, Street Fighter, Mortal Kombat, Toe Jam & Earl, Mario Kart, Golden Eye, MLB games, and Halo. "}
                        {"In high school I really got into PC games like Doom, Quake, Warcraft (none of this WoW trash), Starcraft, and as of late Overwatch. "}
                    </Typography>

                    <Typography variant="subtitle1">{"Television"}</Typography>
                    <Typography className={globalStyles.paragraph}>
                        {"I have a wide range of interests in tv and movies. I'm especially interested in Sci-Fi, action, and mystery. "}
                        {"All-time favorites include Doctor Who, Letterkenny, The Walking Dead, Supernatural, House, Gilmore Girls, Monk, Psych, Stargate, Xena, Buffy the Vampire Slayer, Angel, and Transformers. "}
                        {"A few years ago I basically cut the cord and am now hosting my own Plex media server. I technically still have cable (although I rarely use it), but only because it's cheaper than just having internet. "}
                    </Typography>

                    <Typography variant="subtitle1">{"Music"}</Typography>
                    <Typography className={globalStyles.paragraph}>
                        {"My taste in music is even wider than my taste in television. I like almost all genres of music with the excpetion of rap and newer country music. "}
                        {"Although, I like lots of generes, I really enjoy blues, folk, americana, and classic rock. "}
                        {"Some of the artists on my current playlist are - Johnny Cash, Tyler Childers, Jonny Lang, Bon Jovi, Colter Wall, The Lumineers, The Avett Brothers, Shawn James, Ozzy Osbourne, and AC/DC."}
                        {"My favorite songs are Livin' on a Prayer, Feathered Indians, Lie to Me, and Cocaine Blues."}
                    </Typography>
                </article>
            </section>
        );
    }
}

export default About;
