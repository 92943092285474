import React, { Component, Fragment } from "react";
import { Link, Typography } from "Controls";
import { linkedin, twitter } from "Images";
import globalStyles from "../../styles/styles.scss";
import styles from "./SocialMedia.scss";

class SocialMedia extends Component {
    render() {
        return (
            <Fragment>
                <header>
                    <Typography variant="subtitle2">{"Check me out on..."}</Typography>
                </header>
                <article className={globalStyles.article}>
                    <p>
                        <Link href="https://twitter.com/saraoneal" className={styles.link} variant="body1">
                            <img src={twitter} alt={"Twitter"} className={styles.icon} />
                            {"Twitter @saraoneal"}
                        </Link>
                    </p>
                    <p>
                        <Link href="https://www.linkedin.com/in/saraoneal" className={styles.link} variant="body1">
                            <img src={linkedin} alt={"LinkedIn"} className={styles.icon} />
                            {"LinkedIn"}
                        </Link>
                    </p>
                </article>
            </Fragment>
        );
    }
}

export default SocialMedia;