import React, { Component } from "react";
import { hot } from "react-hot-loader";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, MuiThemeProvider, createMuiTheme } from "Controls";
import Layout from "./Layout";
import styles from "./App.scss";
//import { blue, lightGreen } from "Colors";

const theme = createMuiTheme({
    /*overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#212121",
                color: "#FAFAFA"
            }
        },
        MuiDrawer: {
            paper: {
                backgroundColor: "#212121",
                color: "#FAFAFA"
            }
        }
    },*/
    palette: {
        common: {
            black: "#000",
            white: "#fff"
        },
        background: {
            paper: "#fff",
            default: "#fafafa"
        },
        primary: {
            light: "#B3E5FC",
            main: "#03A9F4",
            dark: "#0277BD",
            contrastText: "#FAFAFA"
        },
        secondary: {
            light: "#424242",
            main: "#212121",
            dark: "#000000",
            contrastText: "#80D8FF"
        },
        error: {
            light: "#e53935",
            main: "#D50000",
            dark: "#B71C1C",
            contrastText: "#fff"
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)"
        }
    },
    typography: {
        useNextVariants: true
    }
});

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <MuiThemeProvider theme={theme}>
                    <div className={styles.root}>
                        <CssBaseline />
                        <Layout />
                    </div>
                </MuiThemeProvider>
            </BrowserRouter>
        );
    }
}

export default hot(module)(App);