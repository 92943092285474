import React from "react";
import { ninja } from "Images";
import styles from "./NinjaLogo.scss";

const NinjaLogo = () => {
    return (
        <div className={styles.logoContainer}>
            <img
                className={styles.ninjaneerLogo}
                src={ninja}
            />
        </div>
    );
};

export default NinjaLogo;