import React, { Component } from "react";
import { Typography } from "Controls";

class Projects extends Component {
    render() {
        return (
            <section>
                <Typography variant="h5">{"My Projects"}</Typography>
            </section>
        );
    }
}

export default Projects;