import React, { Component } from "react";
import { Typography } from "Controls";
import { cadre5 } from "Images";
import globalStyles from "../../styles/styles.scss";
import styles from "./Work.scss";

class Work extends Component {
    render() {
        return (
            <section>
                <header>
                    <a href="https://www.cadre5.com">
                        <img
                            className={styles.c5logo}
                            src={cadre5}
                        />
                    </a>
                </header>
                <article className={globalStyles.article}>
                    <Typography
                        className={globalStyles.header}
                        variant="subtitle2"
                    >
                        {"Sr. Software Engineer"}
                    </Typography>

                    <Typography className={globalStyles.paragraph}>
                        {"I have worked for a software company located in Konxville, TN called Cadre5 since 2007. "}
                        {"Cadre5 is a software consulting company that designs and develops enterprise and scientific software. "}
                        {"Cadre5 was founded in 1999 and employees approximately 50 people in the area. "}
                        {"We have won numerous awards including the ORNL Innovator Award, the 2015 Excellence.gov Award, and the U.S. Small Business Administration's 2014 Small Business Subcontractor of the Year Award for Region 4."}
                    </Typography>
                    
                    <Typography className={globalStyles.paragraph}>
                        {"At Cadre5 I have spent the majority of my time on the G2 project. "}
                        {"G2 is a program management system for government agencies that allows tracking of scope, schedule, metric, budget, and financial information. "}
                        {"It has won the PMI Distinguished Project and AFEI Excellence Awards. "}
                        {"The project consists of approximately 60 team members (managers, developers, QA, etc) from multiple organizations. "}
                        {"Technologies currently being used on the project include ASP.NET MVC, C#, React, SQL Server, and SSRS. "}
                    </Typography>

                    <Typography className={globalStyles.paragraph}>
                        {"On the G2 project I have served in various roles doing report, database, middle-tier, and front-end development. "}
                        {"As of late I have primarily served as a Full-Stack developer, architect, and DevOps Engineer. "}
                    </Typography>
                </article>

                <header>
                    <Typography
                        className={globalStyles.header}
                        variant="h6"
                    >
                        {"Infinite Software Solutions Inc. (ISSI)"}
                    </Typography>
                </header>
                <article className={globalStyles.article}>
                    <Typography
                        className={globalStyles.header}
                        variant="subtitle2"
                    >
                        {"Software Developer Intern"}
                    </Typography>

                    <Typography className={globalStyles.paragraph}>
                        {"ISSI is a software company located in Knoxville, TN. "}
                        {"At ISSI I was responsible for creating, updating, and maintaining coding references hosted on DevGuru.com for T-SQL, JetSQL, XML, and XSLT. "}
                    </Typography>
                </article>
            </section>
        );
    }
}

export default Work;