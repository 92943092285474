import React, { Component } from "react";
import classNames from "classnames";
import axios from "axios";
import { ButtonBase, Typography } from "Controls";
import { autobind } from "Utilities";
import { twitch, streamers } from "./streams.json";
import styles from "./Streamers.scss";

class Streamers extends Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            streamers
        };
    }

    componentWillMount() {
        this.retrieveStreamerStatus();
        this.timer = setInterval(this.retrieveStreamerStatus, 300000);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    retrieveStreamerStatus() {
        const { streamers } = this.state;

        console.log("checking status");

        streamers.forEach((streamer) => {
            const { source } = streamer;

            switch(source.toUpperCase()) {
                case "TWITCH":
                    this.retrieveStreamerTwitchStatus(streamer);
                    break;
            }
        });
    }

    retrieveStreamerTwitchStatus(streamer) {
        const { name } = streamer;
        const url = `${twitch.apiBase}${name}`;

        axios.get(url, {
            headers: { "Client-ID": twitch.clientID }
        })
            .then((response) => {
                const { data } = response;
                const isLive = (data.data && data.data.length > 0 && data.data[0].type === "live");

                this.updateStreamerStatus(streamer, isLive);
            });
    }

    updateStreamerStatus(streamer, isLive) {
        const { streamers } = this.state;
        
        const updatedStreamers = streamers.map((s) => {
            if (s.name === streamer.name) {
                s.isLive = isLive;
            }

            return s;
        });

        this.setState({
            streamers: updatedStreamers
        });
    }

    handleLaunchStream(name, source) {
        let url = "";

        switch(source.toUpperCase()) {
            case "TWITCH":
                url = `${twitch.channelBase}${name}`;
                break;
        }

        window.open(url, "_blank");
    }

    renderStreamIcons() {
        const streamIcons = streamers.map((streamer) => {
            const avatarImage = (streamer.icon) ? streamer.icon : "no-avatar.png";
            const avatar = require(`../../assets/${avatarImage}`);
            const statusClasses = classNames(styles.status, {
                [styles.live]: streamer.isLive
            });

            return (
                <ButtonBase
                    focusRipple
                    key={streamer.name}
                    className={styles.streamerButton}
                    onClick={() => { this.handleLaunchStream(streamer.name, streamer.source); }}
                >
                    <span
                        className={styles.avatarImage}
                        style={{
                            backgroundImage: `url(${avatar})`,
                        }}
                    />
                    <span className={styles.imageBackdrop} />
                    <span className={styles.imageButton}>
                        <Typography
                            component="span"
                            variant="subtitle2"
                            color="inherit"
                            className={styles.imageTitle}
                        >
                            {streamer.name}
                        </Typography>
                    </span>
                    <Typography
                        component="span"
                        variant="caption"
                        className={statusClasses}
                    >
                        {"Live"}
                    </Typography>
                </ButtonBase>
            );
        });

        return (<div className={styles.streamers}>{streamIcons}</div>)
    }

    render() {
        return (
            <section className={styles.container}>
                <header>
                    <Typography variant="h6">{"Streamers"}</Typography>
                </header>
                {this.renderStreamIcons()}
            </section>
        );
    }
}

export default Streamers;