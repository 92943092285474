import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { AppBar, IconButton, Toolbar, withStyles } from "Controls";
import { MenuIcon } from "Icons";
import Banner from "./Banner";
import { autobind } from "Utilities";
import styles from "./Navigation.scss";

const themeStyles = (theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    }
});

const propTypes = {
    classes: PropTypes.object.isRequired,
    isMenuOpen: PropTypes.bool,
    isMenuToolbar: PropTypes.bool,
    onToggleMenu: PropTypes.func
};

class Navigation extends Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    onToggleMenu() {
        const { isMenuOpen, onToggleMenu } = this.props;

        onToggleMenu(!isMenuOpen);
    }

    render() {
        const { classes, isMenuOpen, isMenuToolbar } = this.props;
        const appBarClasses = classNames(styles.appBar, {
            [classes.appBar]: isMenuToolbar
        });
        const menuClasses = classNames(styles.menuIcon, {
            [styles.open]: isMenuOpen,
            [styles.closed]: (isMenuOpen === false)
        });

        return (
            <>
                <AppBar className={appBarClasses} color="secondary" position="sticky">
                    <Toolbar className={styles.container} disableGutters={true}>
                        <IconButton onClick={this.onToggleMenu}>
                            <MenuIcon className={menuClasses} />
                        </IconButton>
                        <h4 className={styles.header}>
                            {"< NINJANEER />"}
                        </h4>
                    </Toolbar>
                </AppBar>
                <Banner />
            </>
        );
    }
}

Navigation.propTypes = propTypes;

export default withStyles(themeStyles)(Navigation);
