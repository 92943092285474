export const displayOptions = {
    formFactors: {
        DESKTOP: "desktop",
        TABLET: "tablet",
        MOBILE: "mobile"
    },
    orientations: {
        LANDSCAPE: "landscape",
        PORTRAIT: "portrait"
    }
};

export function getDisplayDetails(height, width) {
    const { formFactors, orientations } = displayOptions;
    const orientation = (height > width) ? orientations.PORTRAIT : orientations.LANDSCAPE;
    let formFactor;

    if (orientation === orientations.LANDSCAPE) {
        if (width <= 640) {
            formFactor = formFactors.MOBILE;
        }
        else if (width <= 1024) {
            formFactor = formFactors.TABLET;
        }
        else {
            formFactor = formFactors.DESKTOP;
        }
    }
    else {
        if (width <= 485) {
            formFactor = formFactors.MOBILE;
        }
        else if (width <= 975) {
            formFactor = formFactors.TABLET;
        }
        else {
            formFactor = formFactors.DESKTOP;
        }
    }

    return {
        orientation,
        formFactor
    };
}